import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'

// declaring the types for our state
export type PatientState = {
  loading: boolean
  error: any | null
  patients: any[]
  selectedPatient: any | null
  registeredPatient: any | null
  members: any[]
  vitalPatient: any[]
}

const initialState: PatientState = {
  error: null,
  loading: true,
  patients: [],
  selectedPatient: null,
  registeredPatient: null,
  members: [],
  vitalPatient: []
}

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.patients = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    setSelectedPatient: (state, action: PayloadAction<any>) => {
      state.selectedPatient = action.payload
    },
    setRegisteredPatient: (state, action: PayloadAction<any>) => {
      state.registeredPatient = action.payload
    },
    setMembers: (state, action: PayloadAction<any>) => {
      state.members = action.payload
    },
    setVitalPatient: (state, action: PayloadAction<any>) => {
      state.vitalPatient = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setLoading,
  setData,
  setError,
  setSelectedPatient,
  setRegisteredPatient,
  setMembers,
  setVitalPatient,
} = patientSlice.actions

//API Calls
export const registerPatient =
  (values: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => string) => {
      try {
        dispatch(setLoading(true))

        const formData = new FormData();

        // Append all values (handling both text and file fields)
        Object.keys(values).forEach((key) => {
          if (values[key] instanceof File) {
            formData.append(key, values[key]); // Append file
          } else if (Array.isArray(values[key])) {
            values[key].forEach((file) => {
              formData.append(key, file); // Append multiple files if needed
            });
          } else {
            formData.append(key, values[key]); // Append other values
          }
        });

        const response = await axios.post(`/smartclinic/patients`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // const response = await axios.post(`/smartclinic/patients`, values)
        const prescription = response.data.response.data
        // console.log({ prescription })
        dispatch(setLoading(false))
        return `/consultation/${prescription._id}`
      } catch (err) {
        console.error(err)
        dispatch(setError(err))
        dispatch(setLoading(false))
      }
    }

export const addMember = (values: any) => async (dispatch) => {
  try {
    const response = await axios.post(`/addMember `, values)
    const res = await axios.get('/users/members')
    const memberList = res.data.response.data
    dispatch(setMembers(memberList))
    return response
  } catch (err: any) {
    console.error(err)
    notifier.error(err?.response?.data?.error?.[0]?.msg || 'Network Error')
  }
}

export const getPatients =
  (type: string, date?: string | null, patientId?: string | null) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        var response: any = undefined
        var filter = `${type}=true`
        if (date) {
          filter = `${filter}&date=${date}`
        }
        response = await axios.get(`/smartclinic/patients?${filter}`)

        const data = response.data.response.data
        dispatch(setData(data))
        dispatch(setRegisteredPatient(data[0]))
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setError(error))
        dispatch(setLoading(false))
      }
    }

export const getOnePatient =
  (type: string, date?: string | null, patientId?: string | null) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        var response: any = undefined
        var filter = `${type}=true`
        if (date) {
          filter = `${filter}&date=${date}`
        }
        if (patientId) {
          filter = `${filter}&patientId=${patientId}`
        }
        response = await axios.get(`/smartclinic/patients?${filter}`)

        const data = response.data.response.data
        await dispatch(setSelectedPatient(data[0]))
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setError(error))
        dispatch(setLoading(false))
      }
    }


export const getVitalPatients =
  () =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/vitals/patients`)
        const data = response.data.response.data
        dispatch(setVitalPatient(data))
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setError(error))
        dispatch(setLoading(false))
      }
    }


// export const getSubmissionDetails =
//   (id: string) =>
//   async (dispatch: (arg0: { payload: any; type: string }) => void) => {
//     try {
//       // dispatch(setLoading(true))
//       // const response = await axios.get(`/prescription/${id}`)
//       // const data = response.data.response.data
//       // dispatch(setData(data))
//     } catch (error) {
//       dispatch(setError(error))
//     }
//   }

// exporting the reducer here, as we need to add this to the store
export default patientSlice.reducer
