// Chatbot.tsx
import { useEffect, useRef, useState } from 'react'
import { XCircleIcon } from '@heroicons/react/solid'
import { useAppSelector } from '../hooks/home'
import SocketService from '../services/socketService'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

let socket: any = null

const Chatbot = ({
  chatOpen,
  setChatOpen,
}: {
  chatOpen: boolean
  setChatOpen: (val: boolean) => void
}) => {
  const [messages, setMessages] = useState<{ sender: string; text: string }[]>([
    {
      sender: 'bot',
      text: 'Welcome to **Sakhi Chatbot**! How can I assist you today? 😊',
    },
  ])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isStreaming, setIsStreaming] = useState(false)
  const botMessageBuffer = useRef<string>('')
  const chatEndRef = useRef<HTMLDivElement>(null)
  const profileData = useAppSelector((state) => state.profile?.data)
  const franchiseeId = profileData?.franchiseeId?._id
  const profileId = profileData?._id
  const isAiChatbotEmitted = useRef(false)

  // ✅ Initialize and clean up socket
  useEffect(() => {
    if (chatOpen && !socket) {
      initializeSocket()
    }

    if (!chatOpen) {
      cleanUpSocket()
      resetChatState()
    }

    return () => {
      cleanUpSocket()
    }
  }, [chatOpen])

  // ✅ Initialize socket connection
  const initializeSocket = () => {
    socket = SocketService.init()

    if (!socket) return

    console.log('Socket initialized')

    if (!isAiChatbotEmitted.current && franchiseeId) {
      socket.emit('ai-chatbot', { franchiseeId }, (response: any) => {
        console.log('ai-chatbot event response:', response)
      })
      isAiChatbotEmitted.current = true
    }

    // ✅ Handle incoming messages with START/STOP markers
    socket.on('sakhi-chatbot', (data: string) => {
      if (!data || typeof data !== 'string') {
        console.error('Invalid message received:', data)
        return
      }

      if (data === 'START') {
        setIsStreaming(true)
        botMessageBuffer.current = ''
        setIsLoading(true) // ✅ Disable input during streaming
      } else if (data === 'STOP') {
        setIsStreaming(false)
        setIsLoading(false) // ✅ Re-enable input after streaming ends
        const formattedMessage = cleanUpText(botMessageBuffer.current)
        setMessages((prev) => [
          ...prev,
          { sender: 'bot', text: formattedMessage },
        ])
        botMessageBuffer.current = ''
      } else {
        botMessageBuffer.current += data
        setMessages((prev) => {
          const updatedMessages = [...prev]
          if (isStreaming) {
            // ✅ Update last bot message in real-time
            if (
              updatedMessages.length > 0 &&
              updatedMessages[updatedMessages.length - 1].sender === 'bot'
            ) {
              updatedMessages[updatedMessages.length - 1].text = cleanUpText(
                botMessageBuffer.current
              )
            } else {
              updatedMessages.push({
                sender: 'bot',
                text: cleanUpText(botMessageBuffer.current),
              })
            }
          }
          return updatedMessages
        })
      }
    })
  }

  // 🧹 Cleanup socket when chatbot is closed
  const cleanUpSocket = () => {
    if (socket) {
      socket.off('sakhi-chatbot')
      socket.disconnect()
      socket = null
      isAiChatbotEmitted.current = false
      console.log('Socket disconnected')
    }
  }

  // ✨ Reset chat state when chatbot is closed
  const resetChatState = () => {
    setMessages([
      {
        sender: 'bot',
        text: 'Welcome to **Sakhi Chatbot**! How can I assist you today? 😊',
      },
    ])
    botMessageBuffer.current = ''
    setIsLoading(false)
    setIsStreaming(false)
  }

  // 🔄 Scroll to the bottom of the chat on new messages
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  // 📡 Send user message
  const sendMessage = () => {
    if (!input.trim()) return

    setMessages((prev) => [...prev, { sender: 'user', text: input }])
    setInput('')
    setIsLoading(true)

    // if (!socket) {
    //   initializeSocket()
    // }

    // ✅ Send message to server
    socket.emit('sakhi-chatbot', { question: input })

    // Simulate loading state
    setTimeout(() => {
      if (isLoading) {
        setMessages((prev) => [
          ...prev,
          { sender: 'bot', text: '...analyzing your question' },
        ])
      }
    }, 1000)
  }

  // Handle "Enter" key press to send message
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      sendMessage()
    }
  }

  // Handle opening the chat
  const handleChatOpen = () => {
    if (profileId === '6396d9e813d08f00078acf0b') {
      setChatOpen(true)
    }
  }

  // ✨ Clean up abnormal spaces and fix text issues
  // ✨ Clean up abnormal spaces and fix text issues
  const cleanUpText = (text: string) => {
    console.log(text)
    if (!text || typeof text !== 'string') return ''
    return (
      text
        // .replace(/\s{2,}/g, ' ') // Remove multiple spaces
        .replace(/(\d)\s+(\d)/g, '$1$2') // Fix 202 5 or similar
        .replace(/\s+([.,?!])/g, '$1') // Remove space before punctuation
        .replace(/::::/g, `\n`)
        // .replace(/\n/g, '\\n &nbsp; \\n') // Add proper line breaks for markdown
        .trim()
    )
  }

  return (
    <div className="fixed bottom-5 right-6 z-50">
      {/* Floating Button */}
      {!chatOpen && (
        <button
          onClick={handleChatOpen}
          className="flex items-center gap-2 rounded-full bg-blue-600 px-4 py-3 text-white shadow-lg transition-all hover:bg-blue-700"
        >
          <img
            src="./assets/chatbot-svgrepo-com.svg"
            alt="chatImage"
            className="h-6 w-6"
          />
          <span className="text-sm font-semibold">AI Assist</span>
        </button>
      )}

      {/* Chatbox */}
      {chatOpen && (
        <div className="flex h-96 w-72 flex-col rounded-lg bg-white shadow-xl sm:w-80">
          {/* Header */}
          <div className="flex items-center justify-between rounded-t-lg bg-blue-600 p-3 text-white">
            <span className="text-sm font-semibold">Sakhi Chatbot</span>
            <button onClick={() => setChatOpen(false)}>
              <XCircleIcon className="h-5 w-5" />
            </button>
          </div>

          {/* Messages */}
          <div className="flex-1 space-y-2 overflow-y-auto bg-gray-50 p-2">
            {messages.map((msg, idx) => (
              <div
                key={idx}
                className={`w-fit max-w-full whitespace-pre-wrap break-words rounded-lg px-3 py-2 text-xs shadow-sm ${
                  msg.sender === 'user'
                    ? 'ml-auto bg-gray-200'
                    : 'mr-auto bg-blue-100'
                }`}
              >
                {/<[a-z][\s\S]*>/i.test(cleanUpText(msg.text || '')) ? (
                  <div
                    className="markdown"
                    dangerouslySetInnerHTML={{
                      __html: cleanUpText(msg.text || ''),
                    }}
                  />
                ) : (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className="markdown"
                  >
                    {cleanUpText(msg.text || '')}
                  </ReactMarkdown>
                )}
              </div>
            ))}
            {isStreaming && botMessageBuffer.current && (
              <div className="my-1 mr-auto w-fit max-w-full rounded-lg bg-blue-100 px-3 py-2 text-xs shadow-sm">
                <ReactMarkdown remarkPlugins={[remarkGfm]} className="markdown">
                  {cleanUpText(botMessageBuffer.current)}
                </ReactMarkdown>
              </div>
            )}
            <div ref={chatEndRef} />
          </div>

          {/* Input */}
          <div className="flex items-center border-t bg-white p-2">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              className="flex-1 rounded border px-3 py-2 text-xs outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Type a message..."
              disabled={isLoading || isStreaming}
            />
            <button
              onClick={sendMessage}
              className="ml-2 rounded bg-blue-600 px-3 py-2 text-xs text-white transition-all hover:bg-blue-700"
              disabled={isLoading || isStreaming}
            >
              {isLoading || isStreaming ? '...' : 'Send'}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Chatbot
